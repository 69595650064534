
































































































import {  Vue } from 'vue-property-decorator';
import { settings } from '@/variables';
import EventBus from '@/variables'
import { clientService } from '../_services';
import { Client } from '@/types';

// @Component
export default Vue.extend({
    name: "CreateInvoice",
    props: {
        clientKey: String,
        isNew: Boolean,
    },
    data() {
        return {
            selectedClient: {} as any,
            imageSource: "/img/icons/avatar5.jpg",
            countryList: settings.countries,
            eventHub: EventBus,
            searchString: '',
            clients: [] as Client[],
            clientFields: [
                { key: "fullname", label: "Auswahl" },
                { key: "artistName", label: "Künstlername", sortable: "true"},
                { key: "firstName", label: "Vorname", sortable: "true", },
                { key: "lastName", label: "Nachname", sortable: "true", },
                { key: "address", label: "Adresse", sortable: "true", },
                { key: "zipcode", label: "PLZ", sortable: "true", },
                { key: "city", label: "Ort", sortable: "true", },
            ],        }
    },
    methods: {
        submitData() {
            console.log("submitting data " + JSON.stringify(this.selectedClient));
            this.eventHub.$emit("addInvoice", this.selectedClient);

        },
        updateCustomerList(): void {
            clientService.findByName(this.searchString)
                .then(
                clients => {
                    console.log("received clients for table " );
                    this.clients= clients;
                },
                error => {
                    alert("error occured " + error);

                }
            )
        },
        takeCustomerData(customerItem: Client): void {
            console.log("received client" + JSON.stringify(customerItem));
            this.selectedClient.firstName= customerItem.firstName;
            this.selectedClient.artistName= customerItem.artistName;
            this.selectedClient.lastName = customerItem.lastName;
            this.selectedClient.address = customerItem.address;
            this.selectedClient.zipcode = customerItem.zipcode;
            this.selectedClient.city = customerItem.city;
            this.submitData();
        }
    },
    created() {
        console.log("dialog created");
    }
});
